*{
  margin:0; padding:0; border:0; box-sizing: border-box;  
}


.homeContact{
    margin-top: -1vh;
}

.anImg{
    width: 100vw;
    height: 100vh;
    /* height:88vh; */
    object-fit: cover;
}

*{
    font-family: sans-serif;
}

.cont2{
    text-align: center;
    position: absolute;
    bottom: 50%;
    left: 50%;
    transform:translate(-50%, -50% ) ;
    width:150%;
    margin-bottom:12%;
}

.text1{
    color:white;
    font-weight: 700;
    letter-spacing: 0.1vw ;
    margin-bottom: 1vw;
    position: relative;
    animation: text 3s 1;
}

.text2 {
    color: white;
    opacity: 0;
    animation: fadeIn 3s;
    animation-delay: 3s;
    animation-fill-mode: forwards;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}

@keyframes text{
    0%{        
        margin-bottom: -6vw;
    }

    30%{
        letter-spacing: 0.9vw;
        margin-bottom: -6vw;
    }

    80%{
        letter-spacing: 0.1vw;
        margin-bottom: -6vw;
    }
}



@media only screen and (min-width: 366px) {
    @keyframes text{
        0%{        
            margin-bottom: -6vw;
            
        }

        30%{
            letter-spacing: 1vw;
            margin-bottom: -6vw;
        }

        80%{
            letter-spacing: 0.1vw;
            margin-bottom: -6vw;
        }
    }
}


@media only screen and (min-width: 1000px) {
    @keyframes text{
        0%{        
            margin-bottom: -6vw;
            
        }

        30%{
            letter-spacing: 2vw;
            margin-bottom: -6vw;
        }
        
        80%{
            letter-spacing: 0.1vw;
            margin-bottom: -6vw;
        }
    }
}
@media only screen and (max-width: 991px) {
    .homeContact{
        margin-top: 0.2vh;
    }
}