.clientTitle{
    font-size: 250%;
    font-weight:1000;
    text-align:center;
    color:rgb(252, 71, 71);
}

.logoDiv{
    padding-left: 30%;
}

.clients_section2{
    background-image: url("../../assets/images/Site pictures/Site pictures/slide5.jpg");
    padding-top: 2em;
    padding-bottom: 2em;
}

.slick-prev {
    left: 3% !important;
    z-index: 1;
}

.slick-next {   
    right: 3% !important;
    z-index: 1;
}