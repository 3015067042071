body {
  position: relative; 
}
* {
  font-family: Calibri;
}
.section_head{
  font-size: 3vw;
}
.ph_number{
  padding: 10px;
  border: 1px solid white;
  border-radius: 20px;
}
.spaceBottom{
  margin-bottom: 30px;
}
.indivCard1 {
  position: relative;
}
.indivCont1 {
  position: absolute;
  min-height: 85%;
  width: 100%;
  bottom: 0;
}

@media only screen and (min-device-width: 230px) and (max-device-width: 279px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: 8px ;
    line-height: 10px !important ;
  }
  .card-icon{
    width: 50px;
    height: 50px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 14px;
  }
}
@media only screen and (min-device-width: 280px) and (max-device-width: 299px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: x-small ;
    line-height: 15px !important ;
  }
  .card-icon{
    width: 60px;
    height: 60px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 14px;
  }
}
@media only screen and (min-device-width: 300px) and (max-device-width: 330px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: x-small ;
    line-height: 20px !important ;
  }
  .card-icon{
    width: 65px;
    height: 65px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 14px;
  }
}

@media only screen and (min-device-width: 331px) and (max-device-width: 350px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: small ;
    line-height: 20px !important ;
  }
  .card-icon{
    width: 65px;
    height: 65px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 351px) and (max-device-width: 360px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: small ;
    line-height: 25px !important;
  }
  .card-icon{
    width: 80px;
    height: 80px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 361px) and (max-device-width: 380px) {
  .indivCont1 {
    min-height: 90%;
  }
  .service-text {
    font-size: small;
    line-height: 20px !important;
  }
  .card-icon{
    width: 80px;
    height: 80px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 381px) and (max-device-width: 410px) {
  .indivCont1 {
    min-height: 90%;
  }
  .service-text {
    font-size: medium;
    line-height: 20px !important;
  }
  .card-icon{
    width: 80px;
    height: 80px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 411px) and (max-device-width: 425px) {
  .indivCont1 {
    min-height: 85%;
  }
  .service-text {
    font-size: medium;
    line-height: 25px !important;
  }
  .card-icon{
    width: 80px;
    height: 80px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 426px) and (max-device-width: 460px) {
  .indivCont1 {
    min-height: 80%;
  }
  .service-text {
    font-size: medium;
    line-height: 25px !important;
  }
  .card-icon{
    width: 80px;
    height: 80px;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 461px) and (max-device-width: 480px) {
  .indivCont1 {
    min-height: 75%;
  }
  .service-text {
    font-size: medium ;
    line-height: 25px !important ;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 481px) and (max-device-width: 500px) {
  .indivCont1 {
    min-height: 75%;
  }
  .service-text {
    font-size: large ;
    line-height: 25px !important ;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 501px) and (max-device-width: 550px) {
  .indivCont1 {
    min-height: 80%;
  }
  .service-text {
    font-size: large;
    line-height: 35px !important ;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 551px) and (max-device-width: 767px) {
  .indivCont1 {
    min-height: 70%;
  }
  .service-text {
    font-size: large;
    line-height: 35px !important ;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 991px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: medium;
    line-height: 22px !important ;
  }
  .con-col{
    padding: 20px 5px;
    line-height: 13px;
  }
}


@media only screen and (min-device-width: 992px) and (max-device-width: 1199px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: small ;
    line-height: 17px !important;
  }
}
@media only screen and (min-device-width: 1200px) and (max-device-width: 1399px) {
  .indivCont1 {
    min-height: 100%;
  }
  .service-text {
    font-size: medium ;
    line-height: 25px !important;
  }
}
@media only screen and (min-device-width: 1400px)  {
  .indivCont1 {
    min-height: 80%;
  }
  .service-text {
    font-size: medium ;
    line-height: 25px !important;
  }
}

.service-sec {
  background-image: url("./components/assets/img/services/serveBG1.png");
  background-repeat: no-repeat;
}


.pro-img {
  transition: transform 0.5s ease;
  z-index: 11;
}
.pro-img:hover {
  transform: scale(1.1);
}
.op {
  opacity: 0.2;
}
.justify {
  text-align: justify;
  text-justify: inter-word;
  line-height: 20px;
}
.card {
  border: none !important;
}
.pro-text {
  background-color: white;
}

.projects {
  background-image: url(./components/assets/img/projects/projects-bg.jpg);
  background-size: contain;
}
.pro-title{
  background-color: rgb(50,86,170);
  color: white;
}
.project-text {
  font-size: 15px;
  line-height: 25px;
}

.opp {
  opacity: 0.2;
}

.teams-sec {
  display: flex;
  justify-content: center;
  text-align: center;
}
.teams-sec img {
  border-radius: 50%;
}
.teammates {
  width: 150px;
  height: 150px;
}
.tm-1 {
  margin: 60px 50px 0px 50px;
}
.tm-2 {
  margin: 20px 50px 0px 50px;
}
.tm-3 {
  margin: 0px 50px 0px 50px;
}
.tm-4 {
  margin: 20px 50px 0px 50px;
}
.tm-5 {
  margin: 60px 50px 0px 50px;
}
.tm-6 {
  margin: 20px 350px 0px -350px;
}
.tm-7 {
  margin: -20px 100px 0px -220px;
}
.tm-8 {
  margin: -20px 0px 0 50px;
}
.tm-9 {
  margin: 20px -350px 0 100px;
}
.tm-10 {
  margin: -5px 300px 0 -100px;
}

.tm-11 {
  margin: -30px 60px 0 -180px;
}
.tm-12 {
  margin: -5px -80px 0 50px;
}

@media only screen and (min-device-width: 230px) and (max-device-width: 1150px) {
  .teams-sec {
    display: inline;
    justify-content: center;
  }
  .tm-6 {
    margin: 0;
  }
  .tm-7 {
    margin: 0;
  }
  .tm-8 {
    margin: 0;
  }
  .tm-9 {
    margin: 0;
  }
  .tm-10 {
    margin: 0;
  }
  .tm-11 {
    margin: 0;
  }
  .tm-12 {
    margin: 0;
  }
}

.contacts {
  background-color: rgb(11, 30, 68);
  padding-top: 50px;
  padding-bottom: 5px;
}
.contacts p {
  text-align: center;
  color: white;
}
.reach {
  text-decoration: none;
  color: white;
  text-align: center !important;
}
.underline {
  border: 1px solid white;
}
.icon {
  width: 390px !important;
  height: 50px;
}
.nt {
  font-size: 10px;
}
.teamsDiv {
  max-width: 100%;
  width: 100vw;
}
.teamsBody {
  background-color: rgb(230, 230, 250);
}





@media only screen and (max-device-width: 991px) {
  .lower_height{
    line-height: 20px;
  }
}
