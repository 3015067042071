.toJustify {
    text-align: justify;
    text-justify: inter-word;
}

.about-body{
    background-color: rgb(230,230,250);    
    padding:20px;
}

.smol{
    font-size: 1vw;
}

.about_circle {
    border:  5px solid pink;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: absolute;
    top: -18px;
    left: -18px;
    z-index: 1;
}
    
.about-sec1{
    position: relative;
    margin-top: 9%;
    width:100%;
        height:100%;
}

button.accordion-button{
    background: none;
}

div.accBody.accordion-body{
    padding: 0;
}

button.accordion-button[aria-expanded="true"],button.accordion-button[aria-expanded="false"] {
    background-color:rgba(255,255,255, 1);   
}

.accordion-button:focus { 
   box-shadow: none !important;
   color:black !important; 
}

.accordion-button:not(.collapsed) {
    box-shadow: none !important;
    color:black !important; 
} 

.cardText{
    line-height: 2vw;
    font-size: 1vw;
    margin:15px;
}

.section_head{
    font-size: 3vw;
}

.ab_head{
    margin:7px  0  0 15px;
}

div.aCard{
   border-radius: 0px 55px ; 
   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.ab_card_cont{
    padding-top:13vw;
}

.about-sec2{
    position: relative;
    width:100%;
    height:100%;
}

.ab_im{
    width:100%;
    height:auto;
}

@media only screen and (max-width: 767px) {
    .ab_im{
        display:none;
    }

    .ab_card_cont{
        padding-top:0vw;
    }

    .cardText{
        font-size: small;
        line-height: 3vh;
    }

    .smol{
        font-size: small;
    }
}