html {
    scroll-behavior: smooth;
}

.active{
    color: rgb(7, 0, 0) !important;
    text-decoration: none; 
    padding-bottom: 5px;
    font-weight: bold;
}

.navbar{
    min-height:7vh;
    background-color: rgb(230,230,250);  
    border-bottom: solid rgba(0, 0, 0, 0.1) 1px;
}

img.img-fluid{
    max-height: 9vh;
}

.navLink{

    text-transform: uppercase;
    margin:0 14px;
    text-decoration: none; 
    color: black;}

.h3Contact{
    letter-spacing: .2vw;
    font-size: medium;
    padding-top: 5px;
}
button.btn.btn-danger{
    background: rgb(255, 118, 118);
    border: 1px solid  rgb(255, 118, 118);
}

.nav-item{
    letter-spacing: .2vw;
    font-size: medium;
    padding:0 .2vw;
}

@media only screen and (max-width: 767px) {
    .nav-item{
        
        max-width: 84px;
        
    }
}
@media only screen and (min-width:768px) and (max-width: 1119px) {
    .h3Contact{
        letter-spacing: .1vw;
        font-size: small;
    }
    .nav-item{
        letter-spacing: .1vw;
        font-size: small;
        padding:0 0vw;
    }
    a.navLink.nav-link{
        margin: 0;
    }
}

@media only screen and (min-width:880px) and (max-width: 1119px) {
    .nav-item{
        
        letter-spacing: .1vw;
        font-size: medium;
        padding:0 0vw;
    }
    .h3Contact{
        letter-spacing: .1vw;
        font-size: medium;
    }
}